.contact {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 5rem 0rem;
}

.contactTitle {
    display: flex;
    justify-content: center;
}

.contactTitle h1 {
    border-bottom: 1px solid var(--black-200);
    font-family: inherit;
    padding: 2px;
    font-size: 3rem;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.contactMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.contactForm {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contactFormTitle {
    display: flex;
    justify-content: flex-start;
}

.contactFormTitle h2 {
    font-family: inherit;
    font-size: 3rem;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border-bottom: 1px solid var(--black-200);
    padding: 4px;
}

.contactFormInput {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contactFormInput input {
    width: 150%;
    padding: 12px 28px 12px 10px;
    border: none;
    border-radius: 10px;
}

.contactFormInput textarea {
    width: 150%;
    padding: 12px 28px 12px 10px;
    border: none;
    border-radius: 10px;
}

.contactFormInput input::placeholder {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.contactFormInput textarea::placeholder {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.contactIllusrator img {
    width: 550px;
}

@media screen and (max-width: 800px) {
    .contactTitle {
        justify-content: flex-start;
    }

    .contactForm {
        width: 100%;
    }

    .contactFormInput input {
        width: 100%;
    }

    .contactFormInput textarea {
        width: 100%;
    }

    .contactIllusrator {
        display: none;
    }
}